<template>
  <div>
    <div
      class="flix-text-center flix-form-group flix-bg-info"
      style="padding: 20px"
    >
      <div style="display: inline-block">
        <a
          href="#"
          :title="$t('message.back')"
          class="flix-html-a"
          @click.prevent="callback(false)"
        >
          <div class="flix-well">
            <calendarDate :date="date.date" />
          </div>
        </a>
      </div>
    </div>
    <div class="flix-form-group" :key="dates.length">
      <div class="flix-row">
        <div>
          <div class="flix-col-md-12">
            <statusRuler :entries="dates" :key="$store.getters.bookingHash" />
          </div>
        </div>
      </div>
    </div>
    <div class="flix-form-group">
      <div class="flix-row">
        <div v-for="(entry, index) in dates" :key="index">
          <div class="flix-col-md-3">
            <bookingCard :entry="entry" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { date: Object, callback: Function },
  components: {
    calendarDate: function () {
      return import('@/components/bookingCalendar/calendarDate')
    },
    bookingCard: function () {
      return import('@/components/booking/')
    },
    statusRuler: function () {
      return import('@/components/statusRuler')
    }
  },
  data() {
    return {
      dates: [],
      assistent: {}
    }
  },
  methods: {
    getAssistent() {
      var r = {}
      this.$store.getters.assistents.forEach(
        function (assistent) {
          if (assistent.ID === this.date.ID) {
            r = assistent
            return false
          }
        }.bind(this)
      )
      return r
    },
    getDate() {
      var r = []
      this.$store.getters.bookings[this.date.ID].forEach(
        function (data) {
          if (
            this.$createDate(data.begin).getTime() <=
              this.date.date.getTime() &&
            this.$createDate(data.end).getTime() >= this.date.date.getTime()
          ) {
            r.push(data)
          }
        }.bind(this)
      )
      return r
    }
  },
  mounted() {
    this.dates = this.getDate()
    this.assistent = this.getAssistent()
  }
}
</script>
